import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'

import * as firebase from "firebase/app";
import { getAuth } from 'firebase/auth'
// import 'firebase/firestore'
import { getAnalytics } from "firebase/analytics"

var firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

const analytics = getAnalytics(app); //TODO: configure this a vue prototype
// const db = firebase.firestore();
const auth = getAuth();

// const usersCollection = db.collection('users');

export {
  analytics,
  // db,
  // usersCollection,
  auth,
}

// const unsubscribe = auth.onAuthStateChanged(firebaseUser => {
//   new Vue({
//     el: '#app',
//     router,
//     store,
//     render: h => h(App),
//     created() {
//       if (firebaseUser) {
//         store.dispatch('fetchUserProfile', firebaseUser);
//       }
//     },
//   });
//   unsubscribe();
// });

createApp(App).use(Quasar, quasarUserOptions).use(store).use(router).mount('#app')
