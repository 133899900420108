<template>
  <q-page class="flex flex-center">
    Make it worth it!
  </q-page>
</template>

<script>
// @ is an alias to /src
// import Landing from '../components/Landing.vue'

export default {
  name: 'Home',
}
</script>
