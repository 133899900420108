<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated class="glossy">
      <q-toolbar class="bg-green">
        <!-- <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        /> -->

        <q-toolbar-title padding="md">
          Endangered
        </q-toolbar-title>
        <div class="q-pa-md q-gutter-md">
          <q-btn v-if="!this.$store.state.user" flat style="color: white;" label="Login" padding="none" @click="$router.push('login')"/>
          <q-btn v-if="this.$store.state.user" padding="none" flat dense icon="more_vert">
            <q-menu>
              <q-list style="min-width: 100px">
                <q-item clickable v-close-popup @click="signOut()">
                  <q-item-section>Log out</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
          <!-- <q-btn v-if="!currentUser" outline style="color: white;" label="Register" /> -->
        </div>

        <!-- <div>Quasar v{{ $q.version }}</div> -->
      </q-toolbar>
    </q-header>

    <q-page-container>
      <router-view/>
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from 'vue'
import { signOut } from 'firebase/auth'
import { auth } from '@/main'
// import Landing from './components/Landing.vue'
// import router from '@/router'

// const auth = getAuth()

export default {
  name: 'LayoutDefault',
  // data() {
  //   return {
  //     user: this.$store.state.user,
  //     // test: false
  //   }
  // },
  // components: {
  //   Landing
  // },
  methods: {
    signOut: function() {
      signOut(auth).then(() => {
        this.$store.state.user = null
        this.$router.push('login')
      }).catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        const email = error.email
        console.log(`Google sign in error. Code: ${errorCode}; Message: ${errorMessage}; Email: ${email}`)
      })
    }
  },
  setup () {
    return {
      leftDrawerOpen: ref(false)
    }
  }
}
</script>
